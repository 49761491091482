<template>
    <validation-observer v-slot="{ handleSubmit}">
        <v-card>
            <v-card-title>
                Add new name for uri:
            </v-card-title>
            <v-card-subtitle>
                {{ newUri }}
            </v-card-subtitle>
            <v-card-text>
                <uv-text-field name="namespaceName" v-model="nsName" placeholder="Name" rules="required"></uv-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="secondary"
                    @click="$emit('close'); nsName = ''"
                    >Cancel</v-btn
                >
                <v-btn
                    text
                    color="primary"
                    @click="handleSubmit(handleAddNewNamespace)"
                    :loading="isAddingNamespace"
                    >OK</v-btn
                >
            </v-card-actions>
        </v-card>
    </validation-observer>
</template>

<script>
import { mapMutations, mapState } from "vuex";


export default ({
    name: "AddNamespaceForm",
    props: {
        newUri: {
            type: String,
            required: true
        },
        report: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            nsName: "",
            isAddingNamespace: false,
        }
    },
    computed: {
        ...mapState("xbrlData" ,[
                "reportInfo"
        ]),
    },
    watch: {
        nsName: function() {
            return this.nsName;
        }
    },
    methods: {
        ...mapMutations("xbrlData",["setReportBaseInfo"]),
        handleAddNewNamespace(){
            this.isAddingNamespace = true;
            let newReportInfo;
            if(!this.report) {
                newReportInfo = Object.assign({}, this.reportInfo);
            } else {
                newReportInfo = Object.assign({}, this.report);
            }
            newReportInfo.namespaces?.push({ name: this.nsName, url: this.newUri});
            this.$apiHttp({
                method: "PUT",
                url: "/report",
                data: newReportInfo
                })
                .then((response) => {
                    this.setReportBaseInfo(response.data.data);
                })
                .finally(() => {
                    this.isAddingNamespace = false;
                    this.$emit("done");
                    this.nsName = null;
                });         
        },
    }

})
</script>
