<template>
        
    <div class="uv-button" >
        <v-btn v-bind="$attrs" v-on="$listeners"
        ref="button"
        :class="{...this.btnClasses, 'space-between': this.spaceBetween, 'uv-no-margin': this.noMargin}"
        >
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
                <slot :name="name" v-bind="scope"/>
            </template>
        </v-btn>
        <v-tooltip top :activator="$refs.button" v-if="title">
            <span v-html="title"></span>
        </v-tooltip>
    </div>
        
</template>
<script>
export default {
    name: "Button",
    props:{
        btnClasses: null,
        spaceBetween:{
            type: Boolean,
            default: false,
        },
        title:{
            type: String,
        },
        noMargin:{
            type: Boolean,
            default: false,
        }
    }
};
</script>
<style lang="sass">
    .uv-button
        display: inline-flex
        align-items: center

    .uv-button .v-btn.v-btn--outlined
        border-width: 2px

    .uv-button .v-btn.space-between .v-btn__content
        justify-content: space-between

    .uv-button .uv-no-margin
        min-width: unset!important
        min-height: unset!important
        width: unset!important
        height: unset!important
</style>